import * as React from "react";
import useScrollFadeIn from "../useScrollFadein";
import samorder1Img from "../../images/samorder2/samorder1.png";
import srcollMedia from "../../images/scroll.svg";
import styled from "styled-components";

const Containar = styled.div`
 
position:relative;
width:100vw;
height:100vh;
.samorder1-filter{
    width: 100vw;
    height:100vh;
    position: relative;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: -1;
    filter: brightness(0.7);
   img {
    width: 100vw;
    height:100vh;
    object-fit:cover;
    position: inherit;
  }
}
.samorder1-text{
  width: 62%;
  margin: 0 19%;
  position: absolute;
  .samorder1-title {
    font-family: 'Pretendard-ExtraBold';
    color:#ffffff;
    font-size: 4vw;
    margin: 19vw 0 0;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    span:first-child {
      font-family: 'Pretendard-SemiBold';
      font-size: 2.5vw;
    }
  }
}
object{
  width: 2vw;
  position:absolute;
  bottom: 3vw;
  right: 47%;
} 

@media screen and (max-width: 460px){
  .video-filter{
    max-width: 460px;
    max-height: 100vh;
    height:100vh;
    overflow: hidden;
    video {
      height: 100%;
      width: auto;
      margin-left: -75vh;
    }
  }

  .samorder1-text {
    width: 90%;
    margin: 0 5%;
    .samorder1-title {
      font-family: 'Pretendard-Bold';
      font-size: 10.5vw;
      margin: 38vh 0 0;
    }
  }

}
`;

// markup
const SamOrder1 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  return (
    <Containar>
      <div className="samorder1-text">
        <p className="samorder1-title">
          <span {...animation[0]}>인건비 절약, 효율적인 주문</span>
          <span {...animation[1]}>매장 주문,</span>
          <span {...animation[2]}>이제 샘오더로 받으세요.</span>
        </p>
      </div>
      <div className="samorder1-filter">
        <img src={samorder1Img} />
      </div>
    </Containar>
  );
};

export default SamOrder1;
