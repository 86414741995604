import * as React from "react";
import { useState } from "react";
import useScrollFadeIn from "../useScrollFadein";
import samorder7 from "../../images/samorder2/samorder7.png";
import swal from "sweetalert";
import axios from "axios";
import styled from "styled-components";
import ImgModal from "../modal/statement";
const Containar = styled.div`
 
position:relative;
width:100vw;
height:54vw;

.samorder7-text{
  width: 62%;
  margin: 0 19%;
  position: absolute;
  text-align: center;
  .samorder7-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 3vw;
    margin: 3.5vw 0 0;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    span:first-child{
      margin-bottom: 1.5vw;
    }
    span:nth-child(2) {
      font-family: 'Pretendard-Medium';
      font-size: 1.4vw;
      color:#555555;  
    }

    span:nth-child(3)
    {
      font-family: 'Pretendard-Bold';
      margin: 1.5vw 0;
      font-size: 1vw;
    }
  }
}

.samorder7-img{
  img{
    position: absolute;
    z-index: -3;
    width:70vw;
    height:fit-content;
    left: 50%;
    margin-left:-35vw;
   
  }

}


@media screen and (max-width: 460px){

  .samorder7-text {
    width: 90%;
    margin: 0 5%;
    .samorder7-title {
      font-family: 'Pretendard-Bold';
      font-size: 10.5vw;
      margin: 38vh 0 0;
    }
  }

}
`;

const Form = styled.form`
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 37vw;
  margin: 0 auto;

  label {
    color: #555555;
  }

  .user-info {
    display: flex;
    .inputs {
      display: flex;
      flex-direction: column;
      margin: 1vw 0.6vw;
      width: 17.8vw;
      input[type="text"] {
        border: none;
        padding: 0.8vw 1vw;
        margin-top: 0.4vw;
        border-radius: 8px;
        font-family: "Pretendard-Regular";
        font-size: 0.9vw;
        background-color: #f2f8fc;
        ::placeholder {
          color: #cccccc;
        }
      }
    }
  }
  .inquiry {
    margin: 1vw 0;
    width: 100%;
    .check-boxs {
      display: flex;
      .check-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2.4vw;
        width: 100%;
      }
    }
    .inquiry-title {
      margin-bottom: 1vw;
      display: block;
    }
    input[type="radio"] {
      margin-right: 0.4vw;
      :not(:first-child) {
        margin-left: 3.8vw;
      }
    }
  }
  .textarea {
    display: flex;
    flex-direction: column;
    margin: 2vw 0;
    width: 100%;
    label {
      margin-bottom: 1vw;
    }
    textarea {
      height: 7vw;
      border: none;
      padding: 0.8vw 1vw;
      border-radius: 8px;
      font-family: "Pretendard-Regular";
      font-size: 0.9vw;
      resize: none;
      background-color: #f2f8fc;
      ::placeholder {
        color: #cccccc;
      }
    }
  }
  .checked {
    display: flex;
    align-items: center;
    justify-content: center;
    input[type="checkbox"] {
      margin-right: 0.4vw;
    }
  }
  label {
    font-family: "Pretendard-SemiBold";
    font-size: 0.8vw;
    small {
      margin-left: 0.3vw;
      font-family: "Pretendard-Regular";
      font-size: 0.6vw;
      color: #fe8d5c;
    }
    span {
      color: #000000;
      cursor: pointer;
    }
  }
  button {
    background-color: transparent;
    border: 1px solid #000000;
    font-size: 1.1vw;
    width: 80%;
    font-family: inherit;
    padding: 1vw;
    border-radius: 8px;
    margin: 1.6vw 0;
    cursor: pointer;
  }
  .pdf {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 0.8vw;
    text-decoration: underline;
    img {
      margin-right: 0.2vw;
    }
  }
  .submitButton {
    display: none;
  }
  @media screen and (max-width: 1020px) {
    .inquiry {
      .check-boxs {
        .check-box {
          margin-right: 1.6vw;
        }
      }
    }
    @media screen and (max-width: 875px) {
      .inquiry {
        margin: 2vw 0 0;
        .inquiry-title {
          margin-bottom: 2vw;
        }
        .check-boxs {
          display: grid;
          width: 100%;
          flex-wrap: wrap;
          grid-template-columns: repeat(3, 1fr);
          .check-box {
            margin-bottom: 1vw;
            justify-content: flex-start;
          }
        }
      }
    }

    @media screen and (max-width: 460px) {
      width: 90%;
      .user-info {
        flex-direction: column;
        width: 100%;
        .inputs {
          margin: 4vw 0;
          width: 100%;
          input[type="text"] {
            padding: 2vw 3vw;
            margin-top: 2vw;
            font-family: "Pretendard-Regular";
            font-size: 4vw;
            ::placeholder {
              color: #cccccc;
            }
          }
        }
      }
      .inquiry {
        margin: 4vw 0 0;
        .inquiry-title {
          margin-bottom: 3vw;
        }
        .check-boxs {
          .check-box {
            margin-bottom: 5vw;
          }
        }
      }
      label {
        font-size: 4vw;
        font-family: "Pretendard-Regular";

        small {
          margin-left: 3vw;
          font-size: 3.6vw;
        }
      }
      .textarea {
        margin-bottom: 8vw;
        textarea {
          padding: 4vw 3vw;
          margin-top: 2vw;
          font-family: "Pretendard-Regular";
          font-size: 4vw;
          height: 30vw;
        }
      }
      .checked {
        input[type="checkbox"] {
          margin-right: 1.6vw;
        }
      }
      .submitButton {
        display: flex;
        background: #ffffff;
        border-radius: 8px;
        color: inherit;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 4vw;
        width: 100%;
        font-size: 5vw;
        margin: 4vw;
      }
      .pdf {
        font-size: 4vw;
        margin-bottom: 20vw;
        img {
          margin-right: 2vw;
        }
      }
    }
  }
`;
// markup
const SamOrder7 = () => {
  const [info, setInfo] = useState({
    name: "",
    phone: "",
    purpose: "",
    content: "",
    road: "샘오더",
  });

  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
  };

  let submitFlag = false;
  const doubleSubmitCheck = () => {
    if (submitFlag) {
      return submitFlag;
    } else {
      submitFlag = true;
      return false;
    }
  };

  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  const handleOnChange = ({ target }) =>
    setInfo({ ...info, [target.name]: target.value });
  const [typeset, setType] = useState("");
  const [check, setCheck] = useState(false);
  const token = "test";
  let config = {
    headers: {
      "X-JWT-TOKEN": token,
    },
  };

  const sendEmail = async () => {
    try {
      await axios.post(
        "https://api.samoffice.co.kr/mailSendSamlab",
        info,
        config
      );
    } catch (err) {
      console.log(err);
    }
  };

  const sendMess = async () => {
    // var info = Object.assign({}, info, {
    //   select: typeset,
    //   phone: 
    // });
    try {
      await axios.post("https://api.samoffice.co.kr/signSMS", info, config);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      info.purpose = typeset;
      if (
        info.name == "" ||
        info.phone == ""
      ) {
        swal("필수사항을 채워주세요");
        console.log(info);
      } else if (check == false) {
        swal("개인정보 취급방침을 체크해주세요");
        console.log(info);
      } else {
        info.purpose = typeset;
        if (doubleSubmitCheck()) return;

        let response = await axios.post(
          "https://api.samoffice.co.kr/signupSamlab",
          info,
          config
        );

        if (response.status == 200) {
          sendEmail();
          sendMess();
          setInfo({
            ...info,
            name: "",
            phone: "",
            purpose: "",
            content: "",
          });
          swal("신청되었습니다");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Containar>
      <div className="samorder7-text">
        <p className="samorder7-title">
          <span {...animation[0]}>지금 바로 문의하세요!</span>
          <span {...animation[1]}>
            프랜차이즈 가맹 본부 및 대량 주문 시 물량에 따른 메리트를 확실히
            드리겠습니다.
          </span>
          <span {...animation[2]}>
            상담량이 많아 상담신청이 조기 마감될 수 있습니다. 지금 바로
            상담신청하세요!
          </span>
        </p>
        <Form
        // onSubmit={handleSubmit}
        >
          <div className="user-info">
            <div className="inputs">
              <label for="name">
                이름<small>*필수</small>
              </label>
              <input
                id="name"
                name="name"
                type="text"
                onChange={handleOnChange}
                value={info.name}
                placeholder="성함을 입력해 주세요"
              />
            </div>
            <div className="inputs">
              <label for="tel">
                휴대폰 번호<small>*필수</small>
              </label>
              <input
                id="tel"
                name="phone"
                type="text"
                onChange={handleOnChange}
                value={info.phone}
                placeholder="휴대폰 번호를 입력해 주세요"
              />
            </div>
          </div>
          <div className="inquiry">
            <label className="inquiry-title">
              문의목적
            </label>
            <div className="check-boxs">
              <div className="check-box">
                <input
                  id="check1"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check1">견적문의</label>
              </div>
              <div className="check-box">
                <input
                  id="check2"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check2">실물체험</label>
              </div>
              <div className="check-box">
                <input
                  id="check3"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check3">기술문의</label>
              </div>
              <div className="check-box">
                <input
                  id="check4"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check4">렌탈문의</label>
              </div>
              <div className="check-box">
                <input
                  id="check5"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check5">기타</label>
              </div>
            </div>
          </div>
          <div className="textarea">
            <label for="description">
              문의내용
            </label>
            <textarea
              id="description"
              name="content"
              cols="30"
              rows="5"
              value={info.content}
              onChange={handleOnChange}
              placeholder="문의내용을 입력해 주세요."
            />
          </div>
          <div className="checked">
            <input
              type="checkbox"
              id="ischecked"
              value={check}
              onChange={setCheck}
            />
            <label for="ischecked">
              <sapn onClick={openModal}>개인정보취급방침</sapn>을 읽었으며 이에
              동의합니다
            </label>
          </div>
          <button onClick={handleSubmit}>
            <p>문의하기</p>
            <div className="icon-mask"></div>
          </button>
          {/* <button className="submitButton" onClick={handleSubmit}>
            <p>문의하기</p>
          </button> */}
        </Form>
      </div>
      <div className="samorder7-img">
        <img alt="img169" src={samorder7} />
      </div>
      {modalVisible && (
        <ImgModal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
        ></ImgModal>
      )}
    </Containar>
  );
};

export default SamOrder7;
