import * as React from "react";
import useScrollFadeIn from "../useScrollFadein";
import srcollMedia from "../../images/scroll.svg";
import samorder3Img from "../../images/samorder2/samorder3.png";
import styled from "styled-components";
const Containar = styled.div`
 
position:relative;
width:62%;
display:flex;
padding: 11vw 19vw 10vw;
justify-content: space-between;
align-items: center;
background-color: #F6F9FA;
.samorder3-text{
  .samorder3-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.5vw;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    span:first-child{
      margin-bottom: 1.5vw;
    }
    span:nth-child(2),span:nth-child(3) {
      font-family: 'Pretendard-Medium';
      font-size: 1.4vw;
      color:#555555;  
    }
  }
}

.samorder3-img {
  width: 25vw;
  img{
    width: 27vw;
  }
}


@media screen and (max-width: 460px){

  .samorder3-text {
    width: 90%;
    margin: 0 5%;
    .samorder3-title {
      font-family: 'Pretendard-Bold';
      font-size: 10.5vw;
      margin: 38vh 0 0;
    }
  }

}
`;

// markup
const SamOrder3 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  return (
    <Containar>
      <div className="samorder3-text">
        <p className="samorder3-title">
          <span {...animation[0]}>
            무인 키오스크,
            <br />
            아무거나 사용하시겠습니까?
          </span>
          <span {...animation[1]}>키오스크는 무인매장운영의 핵심 입니다</span>
          <span {...animation[2]}>
            어떤 키오스크를 사용하냐에 따라 고객 매출은
            <br />
            물론 매장 재방문률에도 차이가 나기 때문입니다.
          </span>
        </p>
      </div>
      <div className="samorder3-img">
        <img src={samorder3Img} alt='img144'/>
      </div>
    </Containar>
  );
};

export default SamOrder3;
