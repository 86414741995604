import * as React from "react";
import styeld from "styled-components";
import useScrollFadeIn from "../useScrollFadein";
import samorder6 from "../../images/samorder2/samorder6.svg";
const Containar = styeld.div`
 
position:relative;


img{
  width:80%;
  display:block;
  margin 4vw auto;
}


@media screen and (max-width: 460px){

  .samorder6-text {
    width: 90%;
    margin: 0 5%;
    .samorder6-title {
      font-family: 'Pretendard-Bold';
      font-size: 10.5vw;
      margin: 38vh 0 0;
    }
  }

}
`;

// markup
const SamOrder6 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  return (
    <Containar>
      <img alt="img168" className="samorder6-img" src={samorder6} />
    </Containar>
  );
};

export default SamOrder6;
