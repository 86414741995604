import * as React from "react";
import styled from "styled-components";
import bg from "../../images/samorder2/samorder5.png";
import icon1 from "../../images/samorder2/samorder5-1.svg";
import icon2 from "../../images/samorder2/samorder5-2.svg";
import icon3 from "../../images/samorder2/samorder5-3.svg";
import icon4 from "../../images/samorder2/samorder5-4.svg";
import icon5 from "../../images/samorder2/samorder5-5.svg";
import icon6 from "../../images/samorder2/samorder5-6.svg";
import icon7 from "../../images/samorder2/samorder5-7.svg";
import arrow from "../../images/arrow_w.svg";
import arrowActive from "../../images/arrow-active.svg";
import useScrollFadeIn from "../useScrollFadein";
import Slider from "react-slick";
import { Link } from "gatsby";
import { GhostBtn } from "../main/main5";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  height: 48vw;
  a {
    cursor: auto;
  }
  .bg {
    top: 0;
    width: 100%;
    z-index: -99;
    position: absolute;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    .main-title {
      text-align: center;
      margin-top: 6vw;
      font-family: "Pretendard-ExtraBold";
      font-size: 2.5vw; //48px
      white-space: pre-wrap;
      line-height: 1.2;
      color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .main-sub {
      font-family: "Pretendard-SemiBold";
      font-size: 1.2vw; //24px
      margin: 1.8vw 0 1vw;
    }
  }
  .link {
    font-family: "Pretendard-Medium";
    font-size: 0.9vw;
    text-decoration: underline;
  }
  .mobile {
    display: none;
  }
  .pc {
    display: block;
  }
  .slick-slide img {
    display: block;
    width: 15vw;
  }
  @media screen and (max-width: 460px) {
    justify-content: flex-start;
    .mobile {
      display: block;
    }
    .pc {
      display: none;
    }
    height: 170vw;
    .bg {
      width: 100%;
      object-fit: cover;
    }
    .main {
      margin: 0 5%;
      .main-title {
        margin: 20vw 0 5vw;
        font-size: 6.6vw; //60px
        white-space: pre-wrap;
        line-height: 1.3;
      }
      .main-sub {
        font-family: "Pretendard-medium";
        margin: 6vw 5%;
        font-size: 4.5vw;
      }
    }
    .link {
      font-size: 3vw;
    }
  }
`;
const Icons = styled.div`
  white-space: pre-wrap;
  display: ${(props) => (props.pc ? "flex" : "none")};
  scrollbar-width: none !important;
  margin-top: 2vw;
  margin-bottom: 6vw;
  width: 100%;
  ::-webkit-scrollbar {
    display: none !important;
  }
  .icon {
    width: 15vw;
    height: 15vw;

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 2vw;
    margin: 0.6vw;
  }
  img {
    height: 15vw;
  }
  .slick-slider {
    margin: 0 !important;
  }
  .slick-list {
    padding: 0 18.5% !important;
  }
  .slick-active {
    opacity: 0.8 !important;
  }
  .slick-center {
    opacity: 1 !important;
  }
  .slick-slide {
    transition: opacity 1s ease-in-out;
    opacity: 0.2;
    /* :not(.slick-active) {
    } */

    .icon {
      :hover {
        background-color: #54ffeb;
      }
    }
    :nth-child(2n) {
      .icon {
        margin-top: 5vw;
      }
    }
  }

  @media screen and (max-width: 460px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
    margin-top: 10vw;
    .icon {
    }

    .slick-slider {
      margin: 0 !important;
    }
    .slick-list {
      padding: 5% 18.5% !important;
    }
    .slick-slide {
      transition: opacity 0.2s ease-in-out;
      :not(.slick-center) {
        opacity: 0.4;
      }

      transition: opacity 0.2s ease-in-out;
      .icon {
        width: 54vw;
        height: 54vw;
        border-radius: 8vw;
        margin: -4vw 2vw;
        img {
          width: 100%;
          height: 20vw;
          object-fit: contain;
          margin-bottom: 6vw;
        }

        p {
          font-size: 3.8vw;
        }
      }
      :nth-child(2n) {
        .icon {
          margin-top: 5vw;
        }
      }
    }
  }
`;
const GhostBtn1 = styled(GhostBtn)`
  border: 1px solid #fff;
  margin: 1.6vw 0;
  p {
    color: #fff;
  }
  transition: all 0.5s ease-in-out;
  :hover {
    border: 1px solid #000;
  }
`;

function Samkiosk5() {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "13%",
    slidesToShow: 5.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1,
    cssEase: "linear",
  };
  const settings1 = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "13%",
    slidesToShow: 1.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1,
    cssEase: "linear",
  };
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  return (
    <Container>
      <img className="bg pc" src={bg} alt="img150" />
      <Icons pc>
        <Slider {...settings}>
          <div className="icon">
            <img src={icon1} alt='img150'/>
          </div>
          <div className="icon">
            <img src={icon2} alt='img151'/>
          </div>
          <div className="icon">
            <img src={icon3} alt='img152'/>
          </div>
          <div className="icon">
            <img src={icon4} alt='img153'/>
          </div>
          <div className="icon">
            <img src={icon5} alt='img154'/>
          </div>
          <div className="icon">
            <img src={icon6} alt='img155'/>
          </div>
          <div className="icon">
            <img src={icon7} alt='img156'/>
          </div>
          <div className="icon">
            <img src={icon5} alt='img157'/>
          </div>
        </Slider>
      </Icons>
      <Icons mobile>
        <Slider {...settings1}>
          <div className="icon">
            <img alt="160" src={icon1} />
          </div>
          <div className="icon">
            <img alt="161" src={icon2} />
          </div>
          <div className="icon">
            <img alt="162" src={icon3} />
          </div>
          <div className="icon">
            <img alt="163" src={icon4} />
          </div>
          <div className="icon">
            <img alt="164" src={icon5} />
          </div>
          <div className="icon">
            <img alt="165" src={icon6} />
          </div>
          <div className="icon">
            <img alt="166" src={icon7} />
          </div>
          <div className="icon">
            <img alt="167" src={icon5} />
          </div>
        </Slider>
      </Icons>
    </Container>
  );
}

export default Samkiosk5;
