import * as React from "react";
import useScrollFadeIn from "../useScrollFadein";
import samorder4 from "../../images/samorder2/samorder4.png";
import samorder4_1 from "../../images/samorder2/samorder4-1.svg";
import samorder4_2 from "../../images/samorder2/samorder4-2.svg";
import samorder4_3 from "../../images/samorder2/samorder4-3.svg";
import samorder4_4 from "../../images/samorder2/samorder4-4.svg";
import styled from "styled-components";
const Containar = styled.div`
 
position:relative;
width:100vw;
height:54vw;

.samorder4-text{
  width: 62%;
  margin: 0 19%;
  position: absolute;
  .samorder4-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.5vw;
    margin: 19vw 0 0;
    line-height: 1.2;
    
    h1:first-child{
      margin-bottom: 1.5vw;
    }
    h1:nth-child(2),h1:nth-child(3) {
      font-family: 'Pretendard-Medium';
      font-size: 1.4vw;
      color:#555555;  
    }
  }
}

.samorder4-img{
  img{
    height: 20vw;
    position: absolute;
    right:0;
   
  }
  img:nth-child(2){
    margin-right: 25vw;
    margin-top: 10vw;
  }
  img:nth-child(3){
    margin-right: 3vw;
    margin-top: 6.5vw;
  }

  img:nth-child(4){
    margin-right: 25vw;
    margin-top: 28.5vw;
  }
  img:nth-child(5){
    margin-right: 3vw;
    margin-top: 25vw;
  }
  img:first-child{
    z-index: -3;
    width:37vw;
    height:fit-content;
    margin-right: 0vw;
    margin-top: 7vw;
  }

}


@media screen and (max-width: 460px){

  .samorder4-text {
    width: 90%;
    margin: 0 5%;
    .samorder4-title {
      font-family: 'Pretendard-Bold';
      font-size: 10.5vw;
      margin: 38vh 0 0;
    }
  }

}
`;

// markup
const SamOrder4 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  return (
    <Containar>
      <div className="samorder4-text">
        <p className="samorder4-title">
          <span {...animation[0]}>
            기존 키오스크의 문제를
            <br />
            완벽하게 해결하다.
          </span>
          <span {...animation[1]}>샘오더만의 매출 상승의 비결 4가지!</span>
          <span {...animation[2]}>
            샘랩의 기술 '샘오더'로 진짜 무인 매장을 경험해보세요!
          </span>
        </p>
      </div>
      <div className="samorder4-img">
        <img src={samorder4} alt='img145'/>
        <img src={samorder4_1} alt='img146'/>
        <img src={samorder4_2} alt='img147'/>
        <img src={samorder4_3} alt='img148'/>
        <img src={samorder4_4} alt='img149'/>
      </div>
    </Containar>
  );
};

export default SamOrder4;
