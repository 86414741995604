import * as React from "react";
import useScrollFadeIn from "../useScrollFadein";
import samorder2_1 from "../../images/samorder2/samorder2-1.png";
import samorder2_2 from "../../images/samorder2/samorder2-2.png";
import styled from "styled-components";
const Containar = styled.div`
position:relative;
width:100vw;


.samorder2-text{
  width: 62%;
  margin: 0 19%;
  .samorder2-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.5vw;
    margin: 10vw 0 0;
    text-align: center;
    line-height: 1.2;
    span:first-child{
      margin-bottom: 1.5vw;
    }
    span:nth-child(2),span:nth-child(3) {
      font-family: 'Pretendard-Medium';
      font-size: 1.4vw;
      color:#555555;  
    }
  }
}

.samorder2-img {
  width:62%;
  margin: 3vw 19vw 10vw;
  display: flex;
  justify-content: space-between;
  img:first-child {
    width: 40vw;
    height: auto;
  }
  img:first-child {
    margin-top: 2vw;
    width: 30vw;
    height: fit-content;
  }
  img:last-child {
    width: 25vw;
    height: fit-content;
  }
}

@media screen and (max-width: 460px){

  .samorder2-text {
    width: 90%;
    margin: 0 5%;
    .samorder2-title {
      font-family: 'Pretendard-Bold';
      font-size: 10.5vw;
      margin: 38vh 0 0;
    }
  }

}
`;

// markup
const SamOrder2 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  return (
    <Containar>
      <div className="samorder2-text">
        <p className="samorder2-title">
          <span {...animation[0]}>왜 무인 주문 시스템이 대세일까요?</span>
          <span {...animation[1]}>
            연간 인건비 800만원 발생! 지속적으로 올라가는 최저임금!
          </span>
          <span {...animation[2]}>
            고객들은 면대면 주문보다 키오스크 사용이 더 편리하다고 인식하고
            있습니다.
          </span>
        </p>
      </div>
      <div className="samorder2-img">
        <img src={samorder2_1} />
        <img src={samorder2_2} />
      </div>
    </Containar>
  );
};

export default SamOrder2;
