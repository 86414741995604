import * as React from "react";
import Layout from "../components/layout/layout";
import styeld from "styled-components";
import SamOrder1 from "../components/samorder2/samorder1";
import SamOrder2 from "../components/samorder2/samorder2";
import SamOrder3 from "../components/samorder2/samorder3";
import SamOrder4 from "../components/samorder2/samorder4";
import SamOrder5 from "../components/samorder2/samorder5";
import SamOrder6 from "../components/samorder2/samorder6";
import SamOrder7 from "../components/samorder2/samorder7";
import { Helmet } from "react-helmet";

const Containar = styeld.div`
`;
const pageName = "샘오더";
const tag_title = `${pageName} - 샘랩`;
const tag_description = `샘랩의 ${pageName} 페이지 입니다.`;
// markup
const Samorder = () => {
  return (
    <Layout>
      <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr/samorder" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta property="twitter:url" content="https://samlab.co.kr/samorder" />
      </Helmet>
      <SamOrder1 />
      <SamOrder2 />
      <SamOrder3 />
      <SamOrder4 />
      <SamOrder5 />
      <SamOrder6 />
      <SamOrder7 />
    </Layout>
  );
};

export default Samorder;
